import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import VideoList from "./VideoList";
import VideoDetail from "./VideoDetail";
import SearchResults from "./SearchResults"; // Buat komponen ini di langkah berikutnya


const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        {/* Header always at the top */}
        <Header />

        {/* Main Content */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<VideoList />} />
            <Route path="/video/:id" element={<VideoDetail />} />
            <Route path="/videos/category/:slug" element={<VideoList />} />
            <Route path="/videos/tag/:tag" element={<VideoList />} />
            <Route path="/search" element={<SearchResults />} />
            {/* Fallback route for unmatched URLs */}
            <Route
              path="*"
              element={
                <div className="text-center p-10">
                  <h1 className="text-2xl font-semibold">404 - Page Not Found</h1>
                  <p>The page you're looking for doesn't exist.</p>
                </div>
              }
            />
          </Routes>
        </div>

        {/* Footer always at the bottom */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
