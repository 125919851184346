import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Helmet } from "react-helmet";

const VideoList = () => {
  const { slug, tag } = useParams(); // Ambil slug kategori atau tag dari URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q') || ""; // Ambil query parameter 'q' dari URL

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchVideos = (page, category = "", tag = "", searchQuery = "") => {
    let endpoint = "https://just-share.info/stream/videos/";

    if (category) {
      endpoint += `category/${category}/`;
    } else if (tag) {
      endpoint += `tag/${tag}/`;
    }

    if (searchQuery) {
      endpoint = `https://just-share.info/stream/videos/search/?q=${searchQuery}`; // Gunakan endpoint pencarian
    }

    setLoading(true);
    fetch(`${endpoint}?page=${page}`)
      .then((response) => response.json())
      .then((data) => {
        setVideos(data.results);
        const videosPerPage = 8; // Sesuaikan dengan pengaturan pagination Anda
        setTotalPages(Math.ceil(data.count / videosPerPage));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVideos(page, slug, tag, query); // Fetch videos berdasarkan kategori, tag, atau query pencarian
  }, [slug, tag, page, query]); // Re-fetch jika slug, tag, query, atau page berubah

  const generateTitle = () => {
    if (slug) return `Category: ${slug}`;
    if (tag) return `Tag: ${tag}`;
    if (query) return `Search results for: "${query}"`;
    return "All Videos";
  };

  const generateDescription = () => {
    if (slug) return `Explore videos in the category "${slug}"`;
    if (tag) return `Explore videos tagged with "${tag}"`;
    if (query) return `Search results for videos matching "${query}"`;
    return "Explore all videos available on our platform.";
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <PuffLoader color="#4A90E2" size={60} />
        <p className="text-gray-500 ml-4">Loading...</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Helmet>
        <title>{generateTitle()}</title>
        <meta name="description" content={generateDescription()} />
        <meta property="og:title" content={generateTitle()} />
        <meta property="og:description" content={generateDescription()} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="URL_GAMBAR_OG_DEFAULT" />
      </Helmet>
      <h1 className="text-2xl font-semibold mb-6">
        {slug ? `Category: ${slug}` : tag ? `Tag: ${tag}` : query ? `Search results for: "${query}"` : "All Videos"}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {videos.length > 0 ? (
          videos.map((video) => {
            const timeSinceUpload = video?.uploaded_at
              ? formatDistanceToNow(parseISO(video.uploaded_at)) + " ago"
              : "Unknown time";

            return (
              <div key={video.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                <video className="w-full h-48 object-cover" controls>
                  <source
                    src={`https://just-share.info${video.video_file || "/fallback.mp4"}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="p-4">
                  <Link to={`/video/${video.id}`}>
                    <h2 className="text-lg font-semibold text-blue-500 hover:underline">
                      {video.title}
                    </h2>
                  </Link>
                  <p className="text-gray-600">
                    {video.description.length > 200
                      ? `${video.description.slice(0, 200)}...`
                      : video.description}
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    {video.views || 0} views | {timeSinceUpload}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center col-span-full text-gray-500">
            No videos found for your search.
          </p>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button
            onClick={() => setPage(Math.max(page - 1, 1))}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-50"
            disabled={page === 1}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => setPage(index + 1)}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium ${
                page === index + 1 ? "text-blue-600 bg-blue-100" : "text-gray-500 bg-white"
              } border border-gray-300 hover:bg-gray-50`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => setPage(Math.min(page + 1, totalPages))}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-50"
            disabled={page === totalPages}
          >
            Next
          </button>
        </nav>
      </div>
    </div>
  );
};

export default VideoList;
