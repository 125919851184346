import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";
import { PuffLoader } from "react-spinners";

const VideoList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q') || ""; // Ambil query parameter 'q' dari URL

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(""); // State untuk menyimpan pesan error
  
  const fetchVideos = (page, searchQuery = "") => {
    // Validasi panjang query
    if (searchQuery.length < 3) {
      setError("Please enter at least 2 characters for search.");
      setVideos([]); // Kosongkan video jika query invalid
      setLoading(false); // Set loading false karena fetch tidak dilakukan
      return;
    }

    setError(""); // Reset pesan error jika query valid    
    
    const endpoint = `https://just-share.info/stream/videos/search/?q=${searchQuery}`;
    setLoading(true);

    fetch(`${endpoint}&page=${page}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.results) {
          setVideos(data.results);
          const videosPerPage = 8; // Sesuaikan dengan pengaturan pagination Anda
          setTotalPages(Math.ceil(data.count / videosPerPage));
        } else {
          setVideos([]); // Jika data.results tidak ada, set videos menjadi array kosong
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
        setError("Failed to load videos. Please try again later.");
        setVideos([]); // Tangani error dengan mengosongkan hasil video
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVideos(page, query); // Fetch videos berdasarkan query pencarian
  }, [query, page]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <PuffLoader color="#4A90E2" size={60} />
        <p className="text-gray-500 ml-4">Loading...</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-6">
        {query ? `Search results for: "${query}"` : "All Videos"}
      </h1>

      {/* Tampilkan pesan error jika ada */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {videos.length > 0 ? (
          videos.map((video) => {
            const timeSinceUpload = video?.uploaded_at
              ? formatDistanceToNow(parseISO(video.uploaded_at)) + " ago"
              : "Unknown time";

            return (
              <div key={video.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                <video className="w-full h-48 object-cover" controls>
                  <source
                    src={`https://videos.just-share.info${video.video_file}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="p-4">
                  <Link to={`/video/${video.id}`}>
                    <h2 className="text-lg font-semibold text-blue-500 hover:underline">
                      {video.title}
                    </h2>
                  </Link>
                  <p className="text-gray-600">
                    {video.description.length > 200
                      ? `${video.description.slice(0, 200)}...`
                      : video.description}
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    <strong>Category:</strong> {video.category.name} |{" "}
                    <strong>Tags:</strong> {video.tags.map(tag => tag.name).join(', ')} |{" "}
                    <strong>Views:</strong> {video.views} | {timeSinceUpload}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center col-span-full text-gray-500">
            No videos found for "{query}".
          </p>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button
            onClick={() => setPage(Math.max(page - 1, 1))}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-50"
            disabled={page === 1}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => setPage(index + 1)}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium ${
                page === index + 1 ? "text-blue-600 bg-blue-100" : "text-gray-500 bg-white"
              } border border-gray-300 hover:bg-gray-50`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => setPage(Math.min(page + 1, totalPages))}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-50"
            disabled={page === totalPages}
          >
            Next
          </button>
        </nav>
      </div>
    </div>
  );
};

export default VideoList;
