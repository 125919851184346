import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <header className="bg-gray-900 text-white py-4 shadow">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo Section */}
        <div className="flex items-center space-x-4">
          <Link to="/" className="text-2xl font-bold flex items-center">
            <img
              src="https://videos.just-share.info/logo.png"
              alt="Just Share Logo"
              className="w-10 h-10 mr-2"
            />
            Just Share Videos
          </Link>
        </div>

        {/* Search Bar */}
        <form
          onSubmit={handleSearch}
          className="hidden md:flex items-center space-x-2 bg-white text-gray-700 rounded-full px-3 py-1"
        >
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="flex-grow bg-transparent outline-none px-2"
          />
          <button
            type="submit"
            className="text-gray-500 hover:text-gray-700 transition"
          >
            🔍
          </button>
        </form>

        {/* Navigation Links */}

      </div>

      {/* Mobile Search Bar */}
      <div className="md:hidden px-4 mt-2">
        <form onSubmit={handleSearch} className="flex items-center bg-white text-gray-700 rounded-full px-3 py-1">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="flex-grow bg-transparent outline-none px-2"
          />
          <button
            type="submit"
            className="text-gray-500 hover:text-gray-700 transition"
          >
            🔍
          </button>
        </form>
      </div>
    </header>
  );
};

export default Header;
